import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pages: [],
};

const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    addPage: (state, action) => {
      const newPage = {
        id: state.pages.length,
        alt: action.payload,
        analyticSent: false,
      };
      state.pages.push(newPage);
    },
    removePage: (state, action) => {
      state.pages = state.pages.filter((page) => page.id !== action.payload);
    },
    updatePage: (state, action) => {
      const { id, analyticSent1 } = action.payload;
      const updatedText = state.pages.find((page) => page.id === id);
      if (updatedText) {
        updatedText.analyticSent = analyticSent1;
      }
    },
  },
});

export const { addPage, removePage, updatePage } = pagesSlice.actions;
export default pagesSlice.reducer;
