import { Key, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPage, updatePage } from "../../pagesSlice";
import { Analytics } from "firebase/analytics";

type fireKit = {
  analytics: Analytics;
  logEvent: Function;
};

interface source {
  fireKit: fireKit;
  id: any;
  src: string;
  alt: string;
  className: string;
  chapter: string;
}
const LazyImage = ({ fireKit, id, src, alt, className, chapter }: source) => {
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [startTracking, setStartTracking] = useState(false);

  const pages = useSelector((state: any) => state.pages.pages);
  const dispatch = useDispatch();

  const handleAddPage = (alt: string) => {
    if (alt) {
      dispatch(addPage(alt));
    }
  };

  const handleUpdatePage = async (
    id: Key | null | undefined,
    analyticSent1: boolean
  ) => {
    const name = async () => {
      dispatch(updatePage({ id, analyticSent1 }));
    };
    await name();
  };

  useEffect(() => {
    handleAddPage(alt);
  }, []);

  const callbackFunction = (entries: any) => {
    const [entry] = entries;
    if (isVisible === false) {
      setIsVisible(entry.isIntersecting);
    }
  };

  setTimeout(() => {
    setStartTracking(true);
  }, 300);

  const options = useMemo(() => {
    return {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    const currentTarget = targetRef.current;
    if (currentTarget) observer.observe(currentTarget);

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [targetRef, options]);

  useEffect(() => {
    if (startTracking && pages[id]) {
      if (pages[id].analyticSent === false) {
        handleUpdatePage(id, true);
        fireKit.logEvent(fireKit.analytics, "view_chapterpage_allchapters");
        fireKit.logEvent(fireKit.analytics, `view_chapterpage_${chapter}`);
      }
    }
  }, [isVisible]);

  return <img ref={targetRef} src={src} alt={alt} className={className} />;
};
export default LazyImage;
