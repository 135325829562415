const Chapter01 = [
  {
    id: 0,
    image: require("./Pages/AllSkies01_001.jpg"),
    alt: "AllSkies01_001",
  },
  {
    id: 1,
    image: require("./Pages/AllSkies01_002.jpg"),
    alt: "AllSkies01_002",
  },
  {
    id: 2,
    image: require("./Pages/AllSkies01_003.jpg"),
    alt: "AllSkies01_003",
  },
  {
    id: 3,
    image: require("./Pages/AllSkies01_004.jpg"),
    alt: "AllSkies01_004",
  },
  {
    id: 4,
    image: require("./Pages/AllSkies01_005.jpg"),
    alt: "AllSkies01_005",
  },
  {
    id: 5,
    image: require("./Pages/AllSkies01_006.jpg"),
    alt: "AllSkies01_006",
  },
  {
    id: 6,
    image: require("./Pages/AllSkies01_007.jpg"),
    alt: "AllSkies01_007",
  },
  {
    id: 7,
    image: require("./Pages/AllSkies01_008.jpg"),
    alt: "AllSkies01_008",
  },
  {
    id: 8,
    image: require("./Pages/AllSkies01_009.jpg"),
    alt: "AllSkies01_009",
  },
  {
    id: 9,
    image: require("./Pages/AllSkies01_010.jpg"),
    alt: "AllSkies01_010",
  },
  {
    id: 10,
    image: require("./Pages/AllSkies01_011.jpg"),
    alt: "AllSkies01_011",
  },
  {
    id: 11,
    image: require("./Pages/AllSkies01_012.jpg"),
    alt: "AllSkies01_012",
  },
  {
    id: 12,
    image: require("./Pages/AllSkies01_013.jpg"),
    alt: "AllSkies01_013",
  },
  {
    id: 13,
    image: require("./Pages/AllSkies01_014.jpg"),
    alt: "AllSkies01_014",
  },
  {
    id: 14,
    image: require("./Pages/AllSkies01_015.jpg"),
    alt: "AllSkies01_015",
  },
  {
    id: 15,
    image: require("./Pages/AllSkies01_016.jpg"),
    alt: "AllSkies01_016",
  },
  {
    id: 16,
    image: require("./Pages/AllSkies01_017.jpg"),
    alt: "AllSkies01_017",
  },
  {
    id: 17,
    image: require("./Pages/AllSkies01_018.jpg"),
    alt: "AllSkies01_018",
  },
  {
    id: 18,
    image: require("./Pages/AllSkies01_019.jpg"),
    alt: "AllSkies01_019",
  },
  {
    id: 19,
    image: require("./Pages/AllSkies01_020.jpg"),
    alt: "AllSkies01_020",
  },
  {
    id: 20,
    image: require("./Pages/AllSkies01_021.jpg"),
    alt: "AllSkies01_021",
  },
  {
    id: 21,
    image: require("./Pages/AllSkies01_022.jpg"),
    alt: "AllSkies01_022",
  },
  {
    id: 22,
    image: require("./Pages/AllSkies01_023.jpg"),
    alt: "AllSkies01_023",
  },
  {
    id: 23,
    image: require("./Pages/AllSkies01_024.jpg"),
    alt: "AllSkies01_024",
  },
  {
    id: 24,
    image: require("./Pages/AllSkies01_025.jpg"),
    alt: "AllSkies01_025",
  },
  {
    id: 25,
    image: require("./Pages/AllSkies01_026.jpg"),
    alt: "AllSkies01_026",
  },
  {
    id: 26,
    image: require("./Pages/AllSkies01_027.jpg"),
    alt: "AllSkies01_027",
  },
  {
    id: 27,
    image: require("./Pages/AllSkies01_028.jpg"),
    alt: "AllSkies01_028",
  },
  {
    id: 28,
    image: require("./Pages/AllSkies01_029.jpg"),
    alt: "AllSkies01_029",
  },
  {
    id: 29,
    image: require("./Pages/AllSkies01_030.jpg"),
    alt: "AllSkies01_030",
  },
  {
    id: 30,
    image: require("./Pages/AllSkies01_031.jpg"),
    alt: "AllSkies01_031",
  },
  {
    id: 31,
    image: require("./Pages/AllSkies01_032.jpg"),
    alt: "AllSkies01_032",
  },
  {
    id: 32,
    image: require("./Pages/AllSkies01_033.jpg"),
    alt: "AllSkies01_033",
  },
  {
    id: 33,
    image: require("./Pages/AllSkies01_034.jpg"),
    alt: "AllSkies01_034",
  },
  {
    id: 34,
    image: require("./Pages/AllSkies01_035.jpg"),
    alt: "AllSkies01_035",
  },
  {
    id: 35,
    image: require("./Pages/AllSkies01_036.jpg"),
    alt: "AllSkies01_036",
  },
  {
    id: 36,
    image: require("./Pages/AllSkies01_037.jpg"),
    alt: "AllSkies01_037",
  },
  {
    id: 37,
    image: require("./Pages/AllSkies01_038.jpg"),
    alt: "AllSkies01_038",
  },
];

export default Chapter01;
