import { PageAnalytics } from "../../utils/types";
import LazyImage from "./LazyImage";
import Chapter01 from "../../media/Chapter01/Chapter01";
import { useDispatch, useSelector } from "react-redux";
// import { addMovie, removeMovie, updateMovie } from "../../movieSlice";
import { addPage, removePage, updatePage } from "../../pagesSlice";
import {
  Key,
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
  useState,
  useEffect,
} from "react";

const Info = (props: PageAnalytics) => {
  const { fireKit } = props;

  useEffect(() => {
    fireKit.logEvent(fireKit.analytics, "view_info");
  }, []);

  const pages = useSelector((state: any) => state.pages.pages);

  return (
    <div>
      <div>
        <h1
          style={{ display: "flex", justifyContent: "center", color: "white" }}
        >
          WIP
        </h1>
        <h1>All pages</h1>
        {pages.map(
          (page: { id: Key | null | undefined; analyticSent1: boolean }) => (
            <div key={page.id}>{String(JSON.stringify(page))}</div>
          )
        )}

        {Chapter01.map(({ id, image, alt }) => {
          return (
            <LazyImage
              fireKit={fireKit}
              id={id}
              src={image}
              alt={alt}
              className="page"
              chapter="1"
            />
          );
        })}

        <h1>All pages</h1>
        {pages.map(
          (page: { id: Key | null | undefined; analyticSent1: boolean }) => (
            <div key={page.id}>{String(JSON.stringify(page))}</div>
          )
        )}
      </div>
    </div>
  );
};

export default Info;
