import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import "./NavBar.css";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
  { name: "Obras", url: "/" },
  { name: "All Skies", url: "/AllSkies" },
  // { name: "Info", url: "/Info" },
];
type Nav = (() => Window) | undefined;

const DrawerAppBar = (props: Props) => {
  const screenWindow: Nav = props.window;
  const windowSize = React.useRef([window.innerWidth, window.innerHeight]);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Prisma Lotus
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <Link
            to={{
              pathname: item.url,
            }}
            target="_self"
            style={{ textDecoration: "none", color: "#D3D4D9" }}
          >
            <ListItem key={item.name} disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const container =
    screenWindow !== undefined ? () => screenWindow().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        style={{ backgroundColor: "#1C1A1B", color: "#D3D4D9" }}
      >
        <Toolbar
          className={
            windowSize.current[0] > 1023
              ? "containerLargeScreen"
              : "containerSmallScreen"
          }
        >
          <IconButton
            style={{ alignSelf: "flex-start", marginLeft: "18px" }}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            MUI
          </Typography> */}
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
            }}
          >
            {navItems.map((item) => (
              <Link
                to={{
                  pathname: item.url,
                }}
                target="_self"
                style={{ textDecoration: "none", marginBottom: "10px" }}
              >
                <Button
                  key={item.name}
                  sx={{
                    color: "#D3D4D9",
                    marginLeft: "15px",
                    marginRight: "15px",
                  }}
                >
                  {item.name}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#1C1A1B",
              color: "#D3D4D9",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
};

export default DrawerAppBar;
