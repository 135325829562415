import * as React from "react";
import "./Pages.css";
import { PageProps } from "../../utils/types";
import { useRef } from "react";
import { useIsVisible } from "react-is-visible";
import LazyImage from "../Info/LazyImage";

const styling = (isVisible: boolean) => ({
  boxShadow: isVisible
    ? "0px 0px 30px black"
    : "0px 0px 30px rgb(184, 247, 255)",
  transition: "all 2s ease",
});

const SomeComponent = ({ onVisible, ...props }: any) => {
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);

  return <div ref={nodeRef} style={styling(isVisible)} {...props} />;
};

const Pages: React.FC<PageProps> = (props: PageProps) => {
  const { CHAPTER, fireKit } = props;
  const windowSize = React.useRef([window.innerWidth, window.innerHeight]);

  return (
    <div
      className={
        windowSize.current[0] > 1023
          ? "containerLargeScreen"
          : "containerSmallScreen"
      }
    >
      {CHAPTER.map(({ id, image, alt }) => {
        return (
          <SomeComponent>
            <LazyImage
              src={image}
              alt={alt}
              fireKit={fireKit}
              id={id}
              className="page"
              chapter="1"
            />
          </SomeComponent>
        );
      })}
    </div>
  );
};

export default Pages;
