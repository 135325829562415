import Cards from "./Cards";
import Images from "../../media/Images";
import { CardData } from "../../utils/interfaces";
import { useEffect } from "react";
import { PageAnalytics } from "../../utils/types";

const DATA: CardData[] = [
  {
    author: "Prisma Lotus",
    image: Images.mataRobots,
    target: "_blank",
    title: "EL Mata Robots",
    url: "https://www.youtube.com/watch?v=gddOfVd2feY&ab_channel=PrismaLotus",
  },
  {
    author: "Prisma Lotus",
    image: Images.allSkies,
    target: "_self",
    title: "All Skies",
    url: "/AllSkies",
  },
  // {
  //   author: "SoulSowl",
  //   image: Images.sufferInSilence,
  //   target: "_blank",
  //   title: "Suffer In Silence",
  //   url: "https://suffer.thecomicseries.com/",
  // },
  {
    author: "Prisma Lotus",
    image: Images.prismaLand,
    target: "_blank",
    title: "PrismaLand",
    url: "https://www.youtube.com/watch?v=AvTP2mbK8as&list=PLt2ZArTYwRBnLSHSlGSRlOFwzq3nWjMgE&ab_channel=PrismaLotus",
  },

  // {
  //   author: "SoulSowl",
  //   image: Images.components,
  //   target: "_blank",
  //   title: "Components",
  //   url: "/Components",
  // },
];

const CardsPage: React.FC<PageAnalytics> = (props: PageAnalytics) => {
  const { fireKit } = props;

  useEffect(() => {
    fireKit.logEvent(fireKit.analytics, "view_home");
  }, []);

  return (
    <div>
      <Cards DATA={DATA} />
    </div>
  );
};

export default CardsPage;
