import Pages from "./Pages";
import Chapter01 from "../../media/Chapter01/Chapter01";
import { Key, useEffect } from "react";
import { PageAnalytics } from "../../utils/types";

const AllSkiesPage: React.FC<PageAnalytics> = (props: PageAnalytics) => {
  const { fireKit } = props;

  useEffect(() => {
    fireKit.logEvent(fireKit.analytics, "view_allskies");
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Pages CHAPTER={Chapter01} fireKit={fireKit} />
    </div>
  );
};

export default AllSkiesPage;
